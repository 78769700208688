import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';
import 'expose-loader?jqueryUjs!jquery-ujs';
import 'expose-loader?bootstrap!bootstrap-sass';
import 'expose-loader?Cookies!js-cookie';

import jqueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';

// Init Masonry as jQuery plugin
jqueryBridget('masonry', Masonry);
